import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Booking.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Booking() {
  let query = useQuery();
  const service = query.get('service');

  return (
    <div>
      <header>
        <nav>
          <Link to="/">Vanity</Link>
          <Link to="/services">Services</Link>
          <Link to="/booking">Booking</Link>
          <Link to="/signup">Signup</Link>
          <Link to="/login">Login</Link>
        </nav>
      </header>
      <main>
        <h1>Booking</h1>
        <p>You are booking a {service}</p>
        {/* Add booking form here */}
      </main>
      <footer>
        <p>&copy; 2024 Vanity. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Booking;
