import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">Vanity</Link>
      <div className="navbar-links">
        <Link to="/services">Services</Link>
        <Link to="/booking">Booking</Link>
        <Link to="/signup">Signup</Link>
        <Link to="/login">Login</Link>
        <input type="text" placeholder="Search..." className="navbar-search" />
        <div className="navbar-profile">
          <img src="/path/to/profile-icon.png" alt="Profile" className="profile-icon" />
          <Link to="/profile">Profile</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
