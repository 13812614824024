import React, { useState, useEffect } from 'react';
import './ServiceList.css';

const initialServices = [
  { id: 1, name: 'Haircut', image: '/images/haircut.jpg' },
  { id: 2, name: 'Manicure', image: '/images/manicure.jpg' },
  { id: 3, name: 'Makeup', image: '/images/makeup.jpg' },
  { id: 4, name: 'Facial', image: '/images/facial.jpg' },
  { id: 5, name: 'Massage', image: '/images/massage.jpg' },
  { id: 6, name: 'Pedicure', image: '/images/pedicure.jpg' },
];

function ServiceList() {
  const [services, setServices] = useState(initialServices);

  useEffect(() => {
    // Mock API call to fetch more services
    const fetchMoreServices = () => {
      setTimeout(() => {
        setServices((prevState) => [
          ...prevState,
          { id: prevState.length + 1, name: 'Service ' + (prevState.length + 1), image: '/images/service.jpg' },
          { id: prevState.length + 2, name: 'Service ' + (prevState.length + 2), image: '/images/service.jpg' },
          { id: prevState.length + 3, name: 'Service ' + (prevState.length + 3), image: '/images/service.jpg' },
        ]);
      }, 2000);
    };

    fetchMoreServices();
  }, []);

  return (
    <div className="service-list">
      {services.map(service => (
        <div key={service.id} className="service-item">
          <img src={service.image} alt={service.name} />
          <h3>{service.name}</h3>
          <button>Book Now</button>
        </div>
      ))}
    </div>
  );
}

export default ServiceList;
