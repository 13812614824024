import React, { useState, useEffect, useRef } from 'react';
import './Home.css';

const initialServices = [
  { id: 1, name: 'Haircut', image: '/images/haircut.jpg' },
  { id: 2, name: 'Manicure', image: '/images/manicure.jpg' },
  { id: 3, name: 'Makeup', image: '/images/makeup.jpg' },
  { id: 4, name: 'Facial', image: '/images/facial.jpg' },
  { id: 5, name: 'Massage', image: '/images/massage.jpg' },
  { id: 6, name: 'Pedicure', image: '/images/pedicure.jpg' },
];

function Home() {
  const [services, setServices] = useState(initialServices);
  const [isFetching, setIsFetching] = useState(false);
  const serviceListRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsFetching(true);
        }
      },
      { root: serviceListRef.current, threshold: 1.0 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreServices();
  }, [isFetching]);

  const fetchMoreServices = () => {
    setTimeout(() => {
      setServices((prevState) => [
        ...prevState,
        { id: prevState.length + 1, name: 'Service ' + (prevState.length + 1), image: '/images/service.jpg' },
        { id: prevState.length + 2, name: 'Service ' + (prevState.length + 2), image: '/images/service.jpg' },
        { id: prevState.length + 3, name: 'Service ' + (prevState.length + 3), image: '/images/service.jpg' },
      ]);
      setIsFetching(false);
    }, 2000);
  };

  return (
    <div className="home">
      <section className="home-hero">
        <h1>Welcome to Vanity</h1>
        <p>Your go-to platform for booking hair, nails, and makeup services from top professionals.</p>
        <button className="hero-button">View Services</button>
      </section>
      <section className="home-services">
        <h2>Popular Services</h2>
        <div className="service-list" ref={serviceListRef}>
          {services.map((service) => (
            <div key={service.id} className="service-item">
              <img src={service.image} alt={service.name} />
              <h3>{service.name}</h3>
              <button>Book Now</button>
            </div>
          ))}
          <div ref={observerRef} style={{ width: '1px', height: '1px' }}></div>
        </div>
        {isFetching && <p>Loading more services...</p>}
      </section>
    </div>
  );
}

export default Home;
